(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function numbersAnimation() {
  var numbers = document.querySelectorAll('[data-ow="number"]');
  var observerTarget = document.querySelector("[data-numbers]");
  if (numbers.length && observerTarget) {
    // Numbers Animation
    var animation = function animation() {
      numbers.forEach(function (numberElement) {
        var numberElementWidth = getComputedStyle(numberElement).width;
        numberElement.style.minWidth = numberElementWidth;
        var total = Number(numberElement.innerText);
        var increment = Math.floor(total / 100);
        var start = 0;
        var timer = setInterval(function () {
          start = start + increment + 1;
          numberElement.innerText = start;
          if (start > total) {
            numberElement.innerText = String(total).padStart(2, 0);
            clearInterval(timer);
          }
        }, 25 * Math.random());
      });
    }; // === Mutation Event
    var handleMutation = function handleMutation(mutation) {
      var target = mutation[0].target;
      if (target.classList.contains("aos-animate")) {
        observer.disconnect();
        animation();
      }
    };
    var observer = new MutationObserver(handleMutation);
    observer.observe(observerTarget, {
      attributes: true
    });
  }
}
var _default = exports["default"] = numbersAnimation;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function forceAOSanimationOnLoad() {
  var elements = document.querySelectorAll("[data-force-animation]");
  if (elements.length) {
    setTimeout(function () {
      elements.forEach(function (element) {
        return element.classList.add("aos-animate");
      });
    }, 50);
  }
}
var _default = exports["default"] = forceAOSanimationOnLoad;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function headerMobile() {
  var headerNav = document.querySelector('[data-header="nav"]');
  var headerBtn = document.querySelector('[data-header="btn-mobile"]');
  var overlayBg = document.querySelector("[data-overlay-bg]");
  if (headerNav && headerBtn && overlayBg) {
    var toggleHeaderMobile = function toggleHeaderMobile() {
      html.classList.toggle("no-scroll-mobile");
      headerNav.classList.toggle("active");
      headerBtn.classList.toggle("active");
      if (overlayBg.classList.contains("active")) {
        setTimeout(function () {
          overlayBg.classList.remove("active");
        }, 200);
      } else {
        overlayBg.classList.toggle("active");
      }
    };
    var html = document.documentElement;
    headerBtn.addEventListener("click", toggleHeaderMobile);
    overlayBg.addEventListener("click", toggleHeaderMobile);
  }
}
var _default = exports["default"] = headerMobile;

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function hideBtnWhatsOnFooter() {
  var footer = document.querySelector("[data-footer]");
  var btnWhats = document.querySelector("[data-btn-whatsapp]");
  if (footer && btnWhats) {
    var checkBtnWhatsappPosition = function checkBtnWhatsappPosition() {
      var footerTop = footer.getBoundingClientRect().top;
      var isFooterVisible = footerTop - scroll90Percent < 300;
      if (isFooterVisible) {
        btnWhats.classList.remove("active");
      } else if (!btnWhats.classList.contains("active")) {
        btnWhats.classList.add("active");
      }
    };
    var scroll90Percent = window.innerHeight * 0.95;
    checkBtnWhatsappPosition();
    window.addEventListener("scroll", checkBtnWhatsappPosition);
  }
}
var _default = exports["default"] = hideBtnWhatsOnFooter;

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function toast(_ref) {
  var status = _ref.status,
    message = _ref.message;
  var toastContainer = document.querySelector(".toast-container");
  if (toastContainer) {
    var _toast = createToast(status, message);
    toastContainer.appendChild(_toast);
    handleClickCloseToast();
    removeToast(_toast);
  }
}
function createToast(statusToast, messageToast) {
  var toastElement = document.createElement("div");
  toastElement.classList.add("toast-element", statusToast);
  toastElement.innerHTML = "\n      <div class=\"toast-message\">\n        ".concat(messageToast, "\n      </div>\n      <button class=\"toast-close no-config\" data-toast=\"close\">\n        <svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xmlns:svgjs=\"http://svgjs.com/svgjs\" width=\"12\" height=\"11\" x=\"0\" y=\"0\" viewBox=\"0 0 329.26933 329\" style=\"enable-background:new 0 0 512 512\" xml:space=\"preserve\" class=\"\">\n          <g>\n            <path xmlns=\"http://www.w3.org/2000/svg\" d=\"m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0\" fill=\"#ffffff\" data-original=\"#fff\" class=\"\"></path>\n          </g>\n        </svg>\n      </button>\n      <div class=\"toast-progress\"></div>\n    ");
  return toastElement;
}
function removeToast(toastElement, clickedBtnClose) {
  setTimeout(function () {
    toastElement.classList.add("hide");
  }, clickedBtnClose ? 0 : 5000);
  setTimeout(function () {
    toastElement.classList.add("removing-content");
  }, clickedBtnClose ? 500 : 5500);
  setTimeout(function () {
    toastElement.remove();
  }, clickedBtnClose ? 2500 : 8000);
}
function handleClickCloseToast() {
  var btnsCloseToast = document.querySelectorAll('[data-toast="close"]');
  if (btnsCloseToast.length) {
    btnsCloseToast.forEach(function (btn) {
      btn.addEventListener("click", function () {
        var toastTargetClicked = this.parentElement;
        removeToast(toastTargetClicked, true);
      });
    });
  }
}
var _default = exports["default"] = toast;

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function video() {
  var videoContainer = document.querySelector('[data-video="container"]');
  var video = document.querySelector('[data-video="video"]');
  var videoBtnPlay = document.querySelector('[data-video="btn-play"]');
  var videoBtnPause = document.querySelector('[data-video="btn-pause"]');
  if (videoContainer && video && videoBtnPlay) {
    var playVideo = function playVideo() {
      videoContainer.classList.add("playing");
      video.loop = false;
      video.muted = false;
      video.currentTime = 0;
      video.play();
    };
    var pauseVideo = function pauseVideo() {
      videoContainer.classList.remove("playing");
      video.loop = true;
      video.muted = true;
      video.pause();
    };
    videoBtnPlay.addEventListener("click", playVideo);
    videoBtnPause.addEventListener("click", pauseVideo);
    video.onended = function (e) {
      pauseVideo();
    };
  }
}
var _default = exports["default"] = video;

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function formDataToJSON(data) {
  var method = function method(object, pair) {
    var keys = pair[0].replace(/\]/g, '').split('[');
    var key = keys[0];
    var value = pair[1];
    if (keys.length > 1) {
      var i, x, segment;
      var last = value;
      var type = isNaN(keys[1]) ? {} : [];
      value = segment = object[key] || type;
      for (i = 1; i < keys.length; i++) {
        x = keys[i];
        if (i == keys.length - 1) {
          if (Array.isArray(segment)) {
            segment.push(last);
          } else {
            segment[x] = last;
          }
        } else if (segment[x] == undefined) {
          segment[x] = isNaN(keys[i + 1]) ? {} : [];
        }
        segment = segment[x];
      }
    }
    object[key] = value;
    return object;
  };
  var object = Array.from(data).reduce(method, {});
  return JSON.stringify(object);
}
var _default = exports["default"] = formDataToJSON;

},{}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toastfy = _interopRequireDefault(require("../../global/toastfy.js"));
var _formDataToJson = _interopRequireDefault(require("../../helpers/formDataToJson.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function contactForm() {
  var form = document.querySelector('[data-form="contact"]');
  var buttonSubmit = document.querySelector('[data-form="btn-submit"]');
  if (form) {
    var validateContactForm = function validateContactForm() {
      var inputs = form.querySelectorAll("input, textarea");
      var isAllInputsFilled = true;

      // check all inputs is empty
      inputs.forEach(function (elementForm) {
        if (elementForm.value === "" && elementForm.required && !elementForm.disabled) {
          elementForm.classList.add("invalid");
          setTimeout(function () {
            return elementForm.classList.remove("invalid");
          }, 2000);
          isAllInputsFilled = false;
        }
      });
      if (!isAllInputsFilled) {
        (0, _toastfy["default"])({
          status: "error",
          message: "Preencha os campos obrigatórios!"
        });
        return isAllInputsFilled;
      }

      // check name
      var inputName = form.querySelector('[data-form="name"]');
      var inputNameValue = inputName.value;
      var isNameContainNumber = /\d/.test(inputNameValue);
      if (inputNameValue.length <= 2 || isNameContainNumber) {
        (0, _toastfy["default"])({
          status: "error",
          message: "Insira um nome válido"
        });
        inputName.classList.add("invalid");
        setTimeout(function () {
          return inputName.classList.remove("invalid");
        }, 2000);
        isAllInputsFilled = false;
        return isAllInputsFilled;
      }

      // check email
      var inputEmail = form.querySelector('[data-form="email"]');
      var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail.value);
      if (!isValidEmail) {
        (0, _toastfy["default"])({
          status: "error",
          message: "Informe um e-mail válido!"
        });
        inputEmail.classList.add("invalid");
        setTimeout(function () {
          return inputEmail.classList.remove("invalid");
        }, 2000);
        isAllInputsFilled = false;
        return isAllInputsFilled;
      }

      // check whatsapp
      var inputCellphone = form.querySelector('[data-form="cellphone"]');
      var inputCellphoneClean = inputCellphone.value.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").length;
      if (inputCellphone.value !== "" && inputCellphoneClean !== 11) {
        (0, _toastfy["default"])({
          status: "error",
          message: "Informe um número de whatsapp válido!"
        });
        inputCellphone.classList.add("invalid");
        setTimeout(function () {
          return inputCellphone.classList.remove("invalid");
        }, 2000);
        isAllInputsFilled = false;
        return isAllInputsFilled;
      }
      return isAllInputsFilled;
    };
    var submitContactForm = function submitContactForm() {
      var elementsForm = form.querySelectorAll("input, select");
      $.ajax({
        url: baseURL + "/",
        type: "POST",
        data: (0, _formDataToJson["default"])(new FormData(form)),
        contentType: "application/json",
        dataType: "json",
        crossOrigin: false,
        crossDomain: true,
        beforeSend: function beforeSend() {
          buttonSubmit.classList.add("loading");
          (0, _toastfy["default"])({
            status: "warning",
            message: "Enviando dados..."
          });
        },
        success: function success(resp) {
          if (resp.status == "success") {
            (0, _toastfy["default"])({
              status: "success",
              message: "Mensagem enviada com sucesso!"
            });
            elementsForm.forEach(function (elementForm) {
              elementForm.value = "";
            });
            setTimeout(function () {
              // [FELIPE] REDIRECT TO THANKS PAGE
            }, 1000);
          }
        },
        error: function error(_ref) {
          var responseJSON = _ref.responseJSON;
          var msgResponse = (responseJSON === null || responseJSON === void 0 ? void 0 : responseJSON.msg) || "Ops! Erro ao enviar a mensagem, tente novamente!";
          (0, _toastfy["default"])({
            status: "error",
            message: msgResponse
          });
          buttonSubmit.classList.remove("loading");
        },
        complete: function complete() {
          setTimeout(function () {
            buttonSubmit.classList.remove("loading");
          }, 800);
        }
      });
    };
    $('[data-form="cellphone"]').mask("(00) 00000-0000");
    buttonSubmit.addEventListener("click", function (event) {
      event.preventDefault();
      if (validateContactForm()) {
        submitContactForm();
      }
    });
  }
}
var _default = exports["default"] = contactForm;

},{"../../global/toastfy.js":5,"../../helpers/formDataToJson.js":7}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function activeEnterprisesSingleInternLink() {
  var internLinks = document.querySelectorAll("[data-enterprises-single-link]");
  var internSections = document.querySelectorAll("[data-enterprises-single-section]");
  if (internLinks.length && internSections) {
    var activeClassOnLink = function activeClassOnLink() {
      var internSectionsActive = document.querySelectorAll("[data-enterprises-single-section].active");
      var lastInternSectionsActive = internSectionsActive[internSectionsActive.length - 1];
      if (lastInternSectionsActive) {
        var enterprisesSingleSection = lastInternSectionsActive.dataset.enterprisesSingleSection;
        var linkTargetToActive = document.querySelector("[data-enterprises-single-link=".concat(enterprisesSingleSection, "]"));
        if (linkTargetToActive.classList.contains("active")) return;
        internLinks.forEach(function (link) {
          link.classList.remove("active");
        });
        linkTargetToActive.classList.add("active");
        isDeviceMax991px && hideLinks(false);
      } else {
        var firstLink = internLinks[0];
        if (firstLink.classList.contains("active")) return;
        internLinks.forEach(function (link) {
          link.classList.remove("active");
        });
        firstLink.classList.add("active");
        isDeviceMax991px && hideLinks(true);
      }
    };
    var toggleActiveClassSectionOnScroll = function toggleActiveClassSectionOnScroll() {
      internSections.forEach(function (section) {
        var sectionTop = section.getBoundingClientRect().top;
        var isSectionVisible = sectionTop - windowScroll20Percent < 0;
        if (isSectionVisible) {
          section.classList.add("active");
          activeClassOnLink();
        } else if (section.classList.contains("active")) {
          section.classList.remove("active");
          activeClassOnLink();
        }
      });
    };
    var hideLinks = function hideLinks(isIndexZero) {
      if (isIndexZero) {} else {
        var internLinkIndexActive = Array.from(internLinks).findIndex(function (link) {
          return link.classList.contains("active");
        });
        internLinks.forEach(function (link, index) {
          var liTarget = link.parentElement;
          if (index < internLinkIndexActive) {
            liTarget.classList.add("hide");
          } else {
            liTarget.classList.remove("hide");
          }
        });
      }
    };
    var isDeviceMax991px = window.matchMedia("(max-width: 991px)").matches;
    var windowScroll20Percent = window.innerHeight * 0.2;
    toggleActiveClassSectionOnScroll();
    window.addEventListener("scroll", toggleActiveClassSectionOnScroll);
  }
}
var _default = exports["default"] = activeEnterprisesSingleInternLink;

},{}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function galleryEnterprisesSingle() {
  var galleryModal = document.querySelector('[data-gallery="modal"]');
  var galleryItems = document.querySelectorAll('[data-gallery="item"]');
  var galleryModalImg = document.querySelector('[data-gallery="modal-img"]');
  if (galleryItems.length && galleryModal && galleryModalImg) {
    var showGalleryModal = function showGalleryModal(itemTarget, indexTarget) {
      var galleryUrl = itemTarget.dataset.galleryUrl;
      galleryModal.classList.add("active");
      if (isFirstActionToOpenModal) {
        mountGalleryModalContent(indexTarget);
        isFirstActionToOpenModal = false;
      }
      _galleryModalImg.style.backgroundImage = "url(".concat(galleryUrl, ")");
      updateGalleryModalNavContent(indexTarget);
    };
    var mountGalleryModalContent = function mountGalleryModalContent(indexTarget) {
      galleryModalList.innerHTML = Array.from(galleryItems).map(function (item, index) {
        var galleryUrl = item.dataset.galleryUrl;
        return "<li>\n              <button class=\"".concat(indexTarget === index ? "active" : "", "\" data-modal-gallery=\"nav-item\">\n                <img src=\"").concat(galleryUrl, "\" />\n              </button>\n            </li>");
      }).join("");
      setTimeout(function () {
        var navItemsModal = document.querySelectorAll('[data-modal-gallery="nav-item"]');
        navItemsModal.forEach(function (item, index) {
          item.addEventListener("click", function (event) {
            event.preventDefault();
            updateGalleryModalNavContent(index);
            updateGalleryModalImgContent(index);
          });
        });
      }, 100);
    };
    var updateGalleryModalNavContent = function updateGalleryModalNavContent(indexTarget) {
      var navItemsModal = document.querySelectorAll('[data-modal-gallery="nav-item"]');
      navItemsModal.forEach(function (item) {
        return item.classList.remove("active");
      });
      navItemsModal[indexTarget].classList.add("active");
    };
    var updateGalleryModalImgContent = function updateGalleryModalImgContent(indexTarget) {
      var galleryUrl = galleryItems[indexTarget].dataset.galleryUrl;
      _galleryModalImg.style.backgroundImage = "url(".concat(galleryUrl, ")");
    };
    var handleClickGalleryArrows = function handleClickGalleryArrows(type) {
      var navItemsModal = document.querySelectorAll('[data-modal-gallery="nav-item"]');
      var indexImgActive = Array.from(navItemsModal).findIndex(function (item) {
        return item.classList.contains("active");
      });
      var indexUpdated = null;
      if (type === "btn-prev") {
        indexUpdated = indexImgActive - 1;
        if (indexUpdated < 0) {
          indexUpdated = navItemsModal.length - 1;
        }
      } else if (type === "btn-next") {
        indexUpdated = indexImgActive + 1;
        if (indexUpdated > navItemsModal.length - 1) {
          indexUpdated = 0;
        }
      }
      updateGalleryModalNavContent(indexUpdated);
      updateGalleryModalImgContent(indexUpdated);
    };
    var closeGalleryModal = function closeGalleryModal() {
      var galleryModalContent = galleryModal.querySelector(".modal-gallery-content");
      galleryModalContent.style.opacity = 0;
      galleryModalContent.style.webkitTransform = "translate3d(0,0,0)";
      galleryModalContent.style.transform = "translate3d(0,-30px,0)";
      setTimeout(function () {
        galleryModal.classList.remove("active");
        galleryModalContent.style.removeProperty("opacity");
        galleryModalContent.style.removeProperty("webkitTransform");
        galleryModalContent.style.removeProperty("transform");
      }, 300);
    };
    var clickOutsideGalleryModal = function clickOutsideGalleryModal(event) {
      var isContainer = event.target === this;
      if (isContainer) {
        closeGalleryModal(event);
      }
    };
    var _galleryModalImg = document.querySelector('[data-gallery="modal-img"]');
    var galleryModalList = document.querySelector('[data-gallery="modal-list"]');
    var galleryModalBtnPrev = document.querySelector('[data-gallery="btn-prev"]');
    var galleryModalBtnNext = document.querySelector('[data-gallery="btn-next"]');
    var galleryModalBtnClose = document.querySelector('[data-gallery="btn-close"]');
    var isFirstActionToOpenModal = true;
    galleryItems.forEach(function (item, index) {
      item.addEventListener("click", function (event) {
        event.preventDefault();
        showGalleryModal(item, index);
      });
    });
    [galleryModalBtnPrev, galleryModalBtnNext].forEach(function (btn) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        var gallery = btn.dataset.gallery;
        var type = gallery === "btn-prev" ? "btn-prev" : "btn-next";
        handleClickGalleryArrows(type);
      });
    });
    galleryModalBtnClose.addEventListener("click", closeGalleryModal);
    galleryModal.addEventListener("click", clickOutsideGalleryModal);
  }
}
var _default = exports["default"] = galleryEnterprisesSingle;

},{}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function loadMorePhotosGallery() {
  var btnLoad = document.querySelector('[data-gallery="btn-load-more"]');
  if (btnLoad) {
    var loadPhotos = function loadPhotos(event) {
      event.preventDefault();
      $.ajax({
        url: baseURL + "/",
        type: "POST",
        data: "",
        contentType: "application/json",
        dataType: "json",
        crossOrigin: false,
        crossDomain: true,
        beforeSend: function beforeSend() {
          btnLoad.classList.add("loading");
        },
        success: function success() {
          // [FELIPE] DO SOMETHING
          //
          // Não esquecer de sumir o botão quando não tiver mais requisições
          // btnLoad.remove();
        },
        error: function error(_ref) {
          var responseJSON = _ref.responseJSON;
          var msgResponse = (responseJSON === null || responseJSON === void 0 ? void 0 : responseJSON.msg) || "Ops! Erro ao carregar mais fotos, tente novamente!";
          toast({
            status: "error",
            message: msgResponse
          });
          btnLoad.classList.remove("loading");
        },
        complete: function complete() {
          setTimeout(function () {
            btnLoad.classList.remove("loading");
          }, 800);
        }
      });
    };
    btnLoad.addEventListener("click", loadPhotos);
  }
}
var _default = exports["default"] = loadMorePhotosGallery;

},{}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function planPhotosSliderSwiper() {
  var planPhotos = document.querySelector('[data-plan="slider"]');
  if (planPhotos) {
    var planPhotosSlider = new Swiper('[data-plan="slider"]', {
      slidesPerView: 1,
      speed: 500,
      navigation: {
        prevEl: '[data-plan="btn-prev"]',
        nextEl: '[data-plan="btn-next"]'
      }
    });
  }
}
var _default = exports["default"] = planPhotosSliderSwiper;

},{}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function homeEnterprisesSliderSwiper() {
  var homeEnterprises = document.querySelector('[data-our-enterprises="slider"]');
  var homeEnterprisesItems = document.querySelectorAll('[data-our-enterprises="slider"] .swiper-slide');
  if (homeEnterprises) {
    var toggleHomeEnterprisesControls = function toggleHomeEnterprisesControls() {
      var breakpointChecker01 = window.matchMedia("(min-width: 1280px)").matches;
      var breakpointChecker02 = window.matchMedia("(min-width: 768px) and (max-width: 1279px)").matches;
      var breakpointChecker03 = window.matchMedia("(max-width: 767px)").matches;
      if (breakpointChecker01 && homeEnterprisesItems.length > 3) {
        homeEnterprisesControls.classList.add("active");
        return;
      }
      if (breakpointChecker02 && homeEnterprisesItems.length > 2) {
        homeEnterprisesControls.classList.add("active");
        return;
      }
      if (breakpointChecker03 && homeEnterprisesItems.length > 1) {
        homeEnterprisesControls.classList.add("active");
        return;
      }
      homeEnterprisesControls.classList.remove("active");
    };
    var homeEnterprisesControls = document.querySelector('[data-our-enterprises="controls-box"]');
    var homeEnterprisesSlider = new Swiper('[data-our-enterprises="slider"]', {
      slidesPerView: 1.1,
      spaceBetween: 22,
      speed: 500,
      pagination: {
        el: '[data-our-enterprises="pagination"]'
      },
      navigation: {
        prevEl: '[data-our-enterprises="btn-prev"]',
        nextEl: '[data-our-enterprises="btn-next"]'
      },
      breakpoints: {
        1280: {
          slidesPerView: 3.2
        },
        992: {
          slidesPerView: 2.5,
          spaceBetween: 32
        },
        768: {
          slidesPerView: 2.05,
          spaceBetween: 16
        },
        600: {
          slidesPerView: 1.5
        }
      }
    });
    toggleHomeEnterprisesControls();
    window.addEventListener("resize", toggleHomeEnterprisesControls);
  }
}
var _default = exports["default"] = homeEnterprisesSliderSwiper;

},{}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function homeHeroSliderSwiper() {
  var homeHero = document.querySelector('[data-hero="slider"]');
  var homeHeroItems = document.querySelectorAll('[data-hero="item"]');
  if (homeHero && homeHeroItems.length > 1) {
    var homeHeroBarLoadingAnimation = function homeHeroBarLoadingAnimation() {
      var barLoading = document.querySelector(".hero-slider-progress-line");
      barLoading.classList.remove("loading");
      setTimeout(function () {
        barLoading.classList.add("loading");
      }, 50);
    };
    var homeHeroContentAnimation = function homeHeroContentAnimation() {
      var homeHeroItems = document.querySelectorAll('[data-hero="item"]');
      var homeHeroItemActive = document.querySelector('[data-hero="item"].swiper-slide-active');
      homeHeroItems.forEach(function (item) {
        return item.classList.remove("active");
      });
      homeHeroItemActive.classList.add("active");
    };
    var homeHeroSlider = new Swiper('[data-hero="slider"]', {
      speed: 500,
      loop: true,
      autoplay: {
        delay: 6000
      },
      pagination: {
        el: '[data-hero="pagination"]',
        type: "fraction"
      },
      navigation: {
        prevEl: '[data-hero="btn-prev"]',
        nextEl: '[data-hero="btn-next"]'
      }
    });
    var homeHeroBoxControls = document.querySelector('[data-hero="controls-box"]');
    var homeHeroArrow = document.querySelector('[data-hero="arrow-down"]');
    homeHeroBoxControls.classList.add("active");
    homeHeroArrow.classList.add("active");
    homeHeroSlider.on("slideChangeTransitionStart", function () {
      homeHeroBarLoadingAnimation();
    });
    homeHeroSlider.on("slideChangeTransitionEnd", function () {
      homeHeroContentAnimation();
    });
    homeHeroBarLoadingAnimation();
    homeHeroContentAnimation();
  } else if (homeHero) {
    var _homeHeroArrow = document.querySelector('[data-hero="arrow-down"]');
    _homeHeroArrow.classList.add("active");
    homeHeroItems[0].classList.add("active");
  }
}
var _default = exports["default"] = homeHeroSliderSwiper;

},{}],15:[function(require,module,exports){
"use strict";

var _headerMobile = _interopRequireDefault(require("./modules/global/headerMobile.js"));
var _forceAOSanimationOnLoad = _interopRequireDefault(require("./modules/global/forceAOSanimationOnLoad.js"));
var _animationNumbers = _interopRequireDefault(require("./modules/global/animationNumbers.js"));
var _video = _interopRequireDefault(require("./modules/global/video.js"));
var _hideBtnWhatsappOnFooter = _interopRequireDefault(require("./modules/global/hideBtnWhatsappOnFooter.js"));
var _homeHeroSliderSwiper = _interopRequireDefault(require("./modules/pages/home/homeHeroSliderSwiper.js"));
var _homeEnterprisesSliderSwiper = _interopRequireDefault(require("./modules/pages/home/homeEnterprisesSliderSwiper.js"));
var _activeEnterprisesSingleInternLink = _interopRequireDefault(require("./modules/pages/enterprisesSingle/activeEnterprisesSingleInternLink.js"));
var _galleryEnterprisesSingle = _interopRequireDefault(require("./modules/pages/enterprisesSingle/galleryEnterprisesSingle.js"));
var _loadMorePhotosGallery = _interopRequireDefault(require("./modules/pages/enterprisesSingle/loadMorePhotosGallery.js"));
var _planPhotosSliderSwiper = _interopRequireDefault(require("./modules/pages/enterprisesSingle/planPhotosSliderSwiper.js"));
var _contactForm = _interopRequireDefault(require("./modules/pages/contact/contactForm.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
document.addEventListener("DOMContentLoaded", function () {
  (0, _headerMobile["default"])();
  (0, _animationNumbers["default"])();
  (0, _video["default"])();
  (0, _homeEnterprisesSliderSwiper["default"])();
  (0, _activeEnterprisesSingleInternLink["default"])();
  (0, _galleryEnterprisesSingle["default"])();
  (0, _loadMorePhotosGallery["default"])();
  (0, _planPhotosSliderSwiper["default"])();
  (0, _contactForm["default"])();
});
window.addEventListener("load", function () {
  (0, _forceAOSanimationOnLoad["default"])();
  (0, _homeHeroSliderSwiper["default"])();
  (0, _hideBtnWhatsappOnFooter["default"])();
});

},{"./modules/global/animationNumbers.js":1,"./modules/global/forceAOSanimationOnLoad.js":2,"./modules/global/headerMobile.js":3,"./modules/global/hideBtnWhatsappOnFooter.js":4,"./modules/global/video.js":6,"./modules/pages/contact/contactForm.js":8,"./modules/pages/enterprisesSingle/activeEnterprisesSingleInternLink.js":9,"./modules/pages/enterprisesSingle/galleryEnterprisesSingle.js":10,"./modules/pages/enterprisesSingle/loadMorePhotosGallery.js":11,"./modules/pages/enterprisesSingle/planPhotosSliderSwiper.js":12,"./modules/pages/home/homeEnterprisesSliderSwiper.js":13,"./modules/pages/home/homeHeroSliderSwiper.js":14}]},{},[15]);
